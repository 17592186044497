.ag-header-cell-label {
    justify-content: center;
}
.ag-theme-alpine {
    --ag-header-height: 35px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #1976d2;
    /*--ag-header-cell-hover-background-color: rgb(80, 40, 140);*/
    /*--ag-header-cell-moving-background-color: rgb(80, 40, 140);*/
}

.ag-theme-alpine .ag-header-group-cell {
    font-weight: lighter;
    /*font-size: 22px;*/
}
.ag-theme-alpine .ag-header-cell {
    font-size: 18px;
}
.ag-theme-alpine {
    --ag-borders: none;
    --ag-row-border-style: dashed;
    --ag-row-border-width: 5px;
    --ag-row-border-color: rgb(150, 150, 200);
}